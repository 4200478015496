<template>
    <div class="onboarding-step step-wide">
        <h1>{{ $t("Welcome") }}</h1>
        <p class="step-intro">{{ $t("How would you like to proceed?") }}</p>
        <form>
            <div class="step-actions d-flex">
                <router-link @click="delete $store.getters.user.status" :to="{ name: 'Vacancies' }" class="btn btn-secondary">{{ $t('Apply to vacancies') }}</router-link>
                <router-link @click="delete $store.getters.user.status" :to="{ name: 'EditCandidate', params: {'id': $store.getters.user.id} }" class="btn btn-secondary">{{ $t("Edit my resume") }}</router-link>
                <input type="file"
                    ref="file"
                    style="display: none"
                    accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                    @change="uploadCV"
                >
                <button v-if="!fileUploaded" @click="$refs.file.click()" class="btn btn-secondary">{{ $t("Upload CV") }}</button>
            </div>
            <p v-if="fileUploaded" class="action-success-text">{{ $t("CV Uploaded") }}</p>
            <FormErrors v-if="errors['cv']" :errors="errors['cv']" />
            
            <button class="btn back-btn" @click="$emit('previous')">
                {{ $t("Go back") }}
                <svg xmlns="http://www.w3.org/2000/svg" width="19.829" height="21.451" viewBox="0 0 19.829 21.451">
                    <g>
                        <g>
                            <path d="M6.118 1.032L2.8 4.349h8.477a8.6 8.6 0 0 1 8.552 8.551 8.653 8.653 0 0 1-8.551 8.551H1.917v-1.475h9.362a7.175 7.175 0 0 0 7.076-7.076 7.126 7.126 0 0 0-7.077-7.077H2.8L6.118 9.14l-1.032 1.032L0 5.086 5.086 0z" transform="translate(164) translate(-164)"/>
                        </g>
                    </g>
                </svg>
            </button>
        </form>
    </div>
</template>

<script>
import {candidateService} from "@/services/candidateService";
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: "CandidateStep_2",
    data() {
        return {

            fileUploaded: false,
        }
    },
    methods: {
        uploadCV(event) {
            const formData = new FormData();

            formData.append('cv', event.target.files[0])

            candidateService.uploadCVFile(this.$store.getters.user.id, formData)
                .then(() => {
                    this.errors = []
                    this.fileUploaded = true
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
        }
    },
    components: {
        FormErrors
    }
}
</script>
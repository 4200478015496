<template>
    <div class="onboarding-step">
        <h1>{{ $t("Welcome") }}</h1>
        <p class="step-intro">{{ $t("Let's start with some basic information.") }}</p>
        <form>
            <div class="form-group">
                <label class="control-label">{{ $t("What's your job title?") }}</label>
                <input v-model="candidate.job_title" :class="['form-control', {'has-error': errors['job_title']}]" :placeholder="$t('Enter job title')">
                <FormErrors v-if="errors['job_title']" :errors="errors['job_title']"  />
            </div>
            <div class="form-group ">
                <label class="control-label">{{ $t("In what industry do you work?") }}</label>
                <Multiselect
                    v-model="candidate.industry_id"
                    :options="industries"
                    :placeholder="$t('Select industry')"
                    :class="['form-control', {'has-error': errors['industry_id']}]"
                    :searchable="true"
                />
                <FormErrors v-if="errors['industry_id']" :errors="errors['industry_id']"  />
            </div>
            <button @click="next" type="button" class="btn btn-primary" :disabled="!(candidate.job_title && candidate.industry_id) || disabled">
                {{ $t("Next") }}
            </button>
        </form>
    </div>
</template>

<script>
import {userService} from "@/services/userService";
import FormErrors from "@/components/widgets/FormErrors";
import Multiselect from '@vueform/multiselect'

export default {
    name: "CandidateStep_1",
    props: ['candidate'],
    methods: {
        next() {
            this.disabled = true
            userService.updateUserAbout(this.candidate)
                .then(() => this.$emit('next'))
                .catch(error => this.errors = error.response.data.errors || [])
                .finally(() => this.disabled = false)
        }
    },
    components: {
        FormErrors,
        Multiselect
    },
    computed: {
        industries() {
            return this.$store.getters.industries;
        }
    }
}
</script>